<template>
  <div class="stories template-1">
    <page-header>
      <h1>{{ translations.tcSuccessStories }}</h1>
    </page-header>
    <security-banner v-if="!allowPage" :i18n="translations.components" />
    <div v-if="allowPage">
      <page-body :bg="true">
        <section class="body-header">
          <div class="action-bar d-flex justify-content-between">
            <div class="f-container d-flex align-items-center">&nbsp;</div>
            <div class="actions d-flex">
              <b-button
                :to="{ name: 'submit-success-story' }"
                v-if="okToAddStory"
                variant="tertiary"
                class="flex-0 mr-0 mr-sm-3 d-block w-100-sd mb-3 mb-sm-0"
                >{{ translations.tcSubmitASuccessStory }}</b-button
              >
              <Search
                @handleSearchClick="handleSearchClick($event)"
                @searchTermChanged="handleSearchTermChange($event)"
              />
            </div>
          </div>
        </section>
        <section class="ann-cards">
          <div class="row">
            <div v-for="(ann, index) in storiesTranslated" :key="index" class="col-4 mb-4 col-100-sd">
              <AnnCard :obj="ann" @view_more="handleViewMore($event)" :i18n="translations.components"></AnnCard>
            </div>
          </div>
        </section>
        <b-button
          :disabled="moreDisabled"
          variant="tertiary"
          @click="handleMoreStoriesClick"
          class="btn-w-sm flex-0 mt-3 mr-0 mr-sm-3 d-block w-100-sd mb-0"
          >{{ translations.tcMoreSuccessStories }}</b-button
        >
      </page-body>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import AnnCard from '@/components/ann-card/AnnCard.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import Search from '@/components/SearchInput.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'success-stories',
  mixins: [translationMixin],
  data() {
    return {
      moreDisabled: false,
      returnedRows: 6,
      search: '',
      storiesTranslated: [],
      translations: {},
    }
  },
  components: {
    AnnCard: AnnCard,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    Search: Search,
    securityBanner: SecurityBanner,
  },
  methods: {
    ...mapActions({
      getSuccessStories: 'successStory/getSuccessStories',
      searchStories: 'successStory/searchArticles',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedArticleKey: 'user/setSelectedArticleKey',
    }),
    async handleViewMore(evt) {
      await this.setSelectedArticleKey(evt.art_key)
      this.$router.push('/get-connected/stay-informed/success-story')
    },
    async handleMoreStoriesClick() {
      try {
        this.setLoadingStatus(true)
        this.returnedRows += 6
        await Promise.all([
          await this.searchStories({
            search_for: !!this.search ? this.search : null,
            art_type: 1,
            rows: this.returnedRows,
            lng_key: this.userLanguageKey,
          }),
        ]).then(() => {
          this.translateStoriesData()
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    handleSearchTermChange(evt) {
      this.moreDisabled = evt.term.length > 0 && evt.label === 'SEARCH'
    },
    async handleSearchClick(searchTerm) {
      try {
        this.search = searchTerm
        this.moreDisabled = false
        if (searchTerm === '') {
          this.returnedRows = 6
          await this.page_load()
        } else {
          await Promise.all([
            this.setLoadingStatus(true),
            await this.searchStories({
              search_for: searchTerm,
              art_type: 1,
              rows: this.returnedRows,
              lng_key: this.userLanguageKey,
            }),
          ]).then(() => {
            this.translateStoriesData()
          })
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async page_load() {
      try {
        await Promise.all([
          this.setLoadingStatus(true),
          await this.getSuccessStories({
            rows: this.returnedRows,
          }),
        ]).then(() => {
          this.translateStoriesData()
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    translateStoriesData() {
      if (!this.stories || this.stories.length < 1 || !this.translations) {
        this.storiesTranslated = []
        return false
      }
      this.storiesTranslated = this.stories.map((story) => {
        let lookupType = story.topic || ''
        lookupType = this.convertValForTranslation(lookupType)

        story.topic = this.translations.common['success-story-types'].hasOwnProperty(lookupType)
          ? this.translations.common['success-story-types'][lookupType]
          : story.topic
        return story
      })
    },
  },
  computed: {
    ...mapGetters({
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      stories: 'successStory/successStories',
      userLanguageKey: 'user/userLanguageKey',
      userSelectedArticleKey: 'user/userSelectedArticleKey',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
    okToAddStory() {
      return true
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      this.returnedRows = 6
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('ann-card', 'camp-select', 'security-banner'),
        this.getComponentTranslations('common.success-story-types'),
      ]).then((results) => {
        this.translations.common = { 'success-story-types': results[2]['common.success-story-types'] }
        const translatedText = {
          ...results[1],
        }
        this.$set(this.translations, 'components', translatedText)
      })
      await this.page_load()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
.stories {
  .page-body.container {
    max-width: 1160px;
  }
  .page-body {
    margin-top: 34px;
    .body-header {
      .actions {
        @include breakpoint(sm) {
          flex-wrap: wrap;
        }
        .search {
          @include breakpoint(sm) {
            order: 1;
          }
        }
        .btn {
          padding-left: 22px;
          padding-right: 22px;
          @include breakpoint(sm) {
            order: 2;
          }
        }
      }
    }
    .action-bar {
      @include breakpoint(sm) {
        flex-wrap: wrap;
      }
    }
    .f-container {
      @include breakpoint(sm) {
        order: 2;
        align-items: flex-start !important;
      }
      p {
        margin-bottom: 0;
        margin-right: 13px;
        color: $gray-200;
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
        @include breakpoint(sm) {
          margin-right: 3rem;
        }
      }
    }
    .filters {
      @include breakpoint(sm) {
        flex-wrap: wrap;
        order: 1;
      }
      .btn {
        padding: 11px 13px;
        font-weight: 700;
        @include breakpoint(sm) {
          justify-content: center;
          &:nth-of-type(1) {
            width: 33%;
            flex: 0 0 33%;
          }
          &:nth-of-type(2) {
            width: calc(33% + 1px);
            flex: 0 0 calc(33% + 1px);
          }
          &:nth-of-type(3) {
            width: calc(34% + 1px);
            flex: 0 0 calc(34% + 1px);
          }
          &:nth-of-type(4) {
            margin-left: 0;
            margin-top: -1px;
            width: 66%;
          }
          &:nth-of-type(5) {
            width: calc(34% + 1px);
            flex: 0 0 calc(34% + 1px);
            margin-top: -1px;
          }
        }
      }
      .btn-primary {
        color: #fff !important;
      }
      .btn-outline {
        color: $gray-200;
        display: flex;
        align-items: center;
        svg {
          margin-left: 5px;
        }
      }
    }
    .ann-cards {
      .ann-card {
        height: 100%;
        header {
          background-color: #003946;
        }
        footer {
          height: auto;
          background-color: #003946;
          color: #fff;
          font-size: 14px;
          font-weight: 300;
          padding: 1px 0 0;
        }
        main {
          h3.title {
            min-height: 52px;
          }
          div.entry.tags {
            height: 50px;
            div.row {
              height: 70px;
              overflow: hidden;
            }
            div.row div.tags {
              height: 50px;
              margin-top: 15px;
            }
          }
          .entry-content {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
</style>
